<template>
    <div class="capsule_elm">
        <h5>SMS Attachment</h5>
        <label :for="`sms_attachment-${uniqueId}`" class="switch_option capsule_btn">
            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.has_media" :id="`sms_attachment-${uniqueId}`" hidden>
            <div><span></span></div>
        </label>
    </div>
    <div v-if="form.has_media" class="upload_image">
        <label :for="`show_email-${uniqueId}`">
            <input type="file" @change="fileSelected($event)" :id="`show_email-${uniqueId}`" hidden accept="image/jpg, image/jpeg, image/png, audio/mp3, video/mp4, application/pdf">
            <img src="@/assets/images/image2.png">
            <h4>Click to Upload</h4>
            <p>or drag and drop jpg, jpeg, png, mp3, mp4, pdf</p>
        </label>
        <span class="text-danger" v-show="hasError">{{ errorMessage }}</span>
        <div class="sms-media" v-if="form.media.length > 0">
            <div class="chatbox-media" v-for="(image, key) in form.media" :key="key">
                <span v-html="getFileIconByUrl(image.url ? image.url : image, uniqueId, image.type ? image.type : '' )"></span>
                <a href="javascript:void(0);" class="text-danger remove-media" @click="removeMedia(key)"><i class="fa fa-times"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
    import Helper from '@/utils/Helper'
    import { uuid } from 'vue-uuid'
    import { mapActions } from 'vuex'

    export default {
        name: 'SMS Media',

        data () {
            return  {
                form: {
                    has_media: false,
                    media: []
                },
                hasError: 'false',
                errorMessage: '',
                uniqueId: uuid.v4(),
            };
        },

        props: {
            modelValue: Object,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue: {
                handler (media) {
                    const vm = this;

                    if (media.is_default) {
                        vm.form.has_media = media.has_media;
                        vm.form.media = media.media;
                    }
                },
                deep: true
            },

            'form.has_media' (val) {
                const vm = this;

                vm.$emit('update:modelValue', vm.form);
            },
        },

        mounted () {
            const vm = this;

            if (vm.modelValue.is_default) {
                vm.form.has_media = vm.modelValue.has_media;
                vm.form.media = vm.modelValue.media;
            }
        },

        methods: {
            ...mapActions({
                fileUpload: 'commonModule/fileUpload'
            }),

            fileSelected (event) {
                const vm = this;
                const file = event.target.files[0];
                const fileSize = (file.size / 1024 / 1024).toFixed(1);

                if (fileSize > 4.5) {
                    vm.hasError     = true;
                    vm.errorMessage = `File is too big (${fileSize} MB). Max filesize: 4.5 MB.`;
                } else {
                    vm.hasError     = false;
                    vm.errorMessage = '';

                    let formData = new FormData();
                    formData.append('file', file);

                    vm.fileUpload(formData).then((file) => {
                        vm.form.media = [];
                        vm.form.media.push(file.url);
                        vm.$emit('update:modelValue', vm.form);
                    });
                }
            },

            getFileIconByUrl (image, uniqueId, contentType = '') {
                return Helper.getFileIconByUrl(image, uniqueId, contentType);
            },

            removeMedia (index) {
                const vm = this;

                vm.form.media.splice(index, 1);

                vm.$emit('update:modelValue', vm.form);
            },
        },
    };
</script>
<style scoped>
.upload_image img{
    max-width: 50px;
}
.upload_image label{
    background: #f9f9f9;
    border-radius: 6px;
}
</style>
